
import React, { useState,useEffect  } from 'react';
import Typography from '@mui/material/Typography';
import Cookies from 'js-cookie';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import {APP_LABELS} from './common/label.js'
import MaterialTable from "material-table";
import Alert from '@mui/material/Alert';
import AppHeader from "./AppHeader";
import ConfigJSONViewer from "./common/dialogs/configJSONViewerDialog";

import { TEMPLATE_SERVER_URL } from './common/constants.js'
import { useNavigate } from "react-router-dom";
import { Box } from "@material-ui/core";
import CircularProgress from '@mui/material/CircularProgress';
import ToastAlert from './common/toast/toastWithAlert';
import VariableInputDialog from './common/dialogs/variableInputDialog.js';



function Retriever(props) {
      const [dialogOpen, setDialogOpen] = React.useState(false);
      const [selectedRowData, setSelectedRowData] = React.useState(null);
      const [deployToSourceOrg, setDeployToSourceOrg] = useState(false);
      const [deployToOtherOrg, setDeployToOtherOrg] = useState(false);
      const [expandCredentials, setExpandCredentials] = useState(false);
      const [username , setUsername] = useState('')
      const [password , setPassword] = useState('')
      const [sourceOrgChecked, setSourceOrgChecked] = useState(false);
      const [otherOrgChecked, setOtherOrgChecked] = useState(false);
      const [formData, setFormData] = useState(new FormData());
      const [customDomainFlag, setCustomDomainFlag] = useState(false);
      const [customDomain, setCustomDomain] = useState('');
      const [errorLogin, setErrorLogin] = useState(false);
      const [errorLoginMsg, setErrorLoginMsg] = useState('');
      const [flagLoader, setFlagLoader] = useState(false);
      const [loading, setLoading] = useState(false);
      const [progress, setProgress] = useState(0);
       
        // function to update state of email with value
        // enter by user in form
        const handleUsernameChange =(e)=>{
          console.log(e.target.value)
          setUsername(e.target.value);
          }
          // function to update state of password with
          // value enter by user in form

          const handlePasswordChange =(e)=>{
          console.log(e.target.value);
          setPassword(e.target.value);
      }

      const handleCustomDomainChange =(e)=>{
            setCustomDomain(e.target.value);
        }

      const handleDomainFlagChange =(e)=>{
          setCustomDomainFlag(!customDomainFlag);
        }

        const handleSubmit = (e, username, password,customDomain) => {
          e.preventDefault();
          if (selectedRowData) {
            handleDeployTemplate(selectedRowData, username, password,customDomain);
          }
        };
      async function authenticationRequest(url) {
        setErrorLogin(false);
        setErrorLoginMsg('');
        setFlagLoader(true);
        // Default options are marked with *
        console.log(url)
        fetch(url, {
          mode: 'cors',
          method: 'GET', // * GET, POST, PUT, DELETE, etc.
          headers : new Headers ({
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          })
        })
        .then(response => {
                console.log('response', response);
                return response.json();
         }).then(data => {
                console.log('data', data);
                Cookies.set('accessToken',data.sessionId, { expires: 1 })
                var url = new URL(data.serverUrl);
                
                Cookies.set('instanceUrl', url.origin, { expires: 1 });
                Cookies.set('username',data.userName, { expires: 1 });
                Cookies.set('password',data.password, { expires: 1 });
                Cookies.set('customDomainFlag',data.customDomainFlag, { expires: 1 });
                Cookies.set('customDomain',data.customDomain, { expires: 1 });
                Cookies.set('userFullName',data.userFullName, { expires: 1 })
                Cookies.set('organizationId',data.organizationId, { expires: 1 })
                console.log(Cookies.get('accessToken'),"afterset");
                navigate('table');
                setFlagLoader(false);
         }).catch((e)=> {
                console.log("exception: ", e);
                setErrorLogin(true);
                setErrorLoginMsg(APP_LABELS['LOGIN_ERROR_MSG']);
                setFlagLoader(false);
        });
        
    }
  
    const handleCheckboxChange = (event) => {
      const { name, checked } = event.target;
      if (name === 'deployToOtherOrg') {
        setDeployToOtherOrg(checked);
        if (checked) {
          setExpandCredentials(true);
          setDeployToSourceOrg(false);
        } else {
          setExpandCredentials(false);
        }
      } else if (name === 'deployToSourceOrg') {
        setDeployToSourceOrg(checked);
        if (checked) {
          setDeployToOtherOrg(false);
          setExpandCredentials(false);
        }
      }
    };
   
      

    const [open, setOpenDialog] = React.useState(false);
    const [openVariableDialog, setVariableInputDialog] = React.useState(false);
    const[configData, setConfigData] = React.useState('');
    const[variableDialogData, setVariableDialogData] = React.useState([]);

    const [retrieveList, setRetrieveList]=useState([]);
    const accessToken=Cookies.get('accessToken')
    const instanceUrl= Cookies.get('instanceUrl')
    const userInfo = {
          username: Cookies.get('username'),
          password: Cookies.get('password'), 
          customDomainFlag: Cookies.get('customDomainFlag'),
          customDomain: Cookies.get('customDomain,'),
          fullName: Cookies.get('userFullName'), 
          organizationId: Cookies.get('organizationId')
    };

    const [flagSnackbar, setFlagSnackbar]= useState(false);
    const [snackMessage, setSnackMessage] = React.useState("");
    const [snackVariant, setSnackVariant] = React.useState("");

    let navigate = useNavigate();

    useEffect(() => {
        if(instanceUrl && accessToken){
            getData('/retrieveFileList',{  instanceUrl: instanceUrl, accessToken: accessToken })
                .then(data =>{
                    setRetrieveList(data)
            }); 
        }
        else {
          navigate('/');
        }

    },[instanceUrl,accessToken])

    async function getData(url='', data = {}) {
        // Default options are marked with *
        beforeRequest();
        const response = await fetch(TEMPLATE_SERVER_URL + url + `?instanceUrl=${data.instanceUrl}&accessToken=${data.accessToken}&organizationId=${userInfo.organizationId}`, {
          mode: 'cors',
          method: 'GET', // *GET, POST, PUT, DELETE, etc.
          headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
          
        });
        postRespone();
        return response.json(); // parses JSON response into native JavaScript objects
      }

      async function getOtherData(url='', data = {}) {
        // Default options are marked with *
        beforeRequest();
        const response = await fetch(TEMPLATE_SERVER_URL + url + `?instanceUrl=${data.instanceUrl}&accessToken=${data.accessToken}&organizationId=${userInfo.organizationId}&folderName=${data.folderName}`, {
          mode: 'cors',
          method: 'GET', // *GET, POST, PUT, DELETE, etc.
          headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
          
        });
        postRespone();
        return response.json(); // parses JSON response into native JavaScript objects
      }

      const closeDialog = () => {
        setOpenDialog(false);
      };

    const showConfig= (data)=>{
        setConfigData(data);
        setOpenDialog(true);
    }


    const createDetailPanel = (rowData)=>{
        return (
            <div className='detail-panel-div'>
                <p>All Files</p>
                {
                    rowData.fileList.map(filename=>(
                        <li>{filename}</li>
                    ))
                }
            </div>
        )
    }

    const createConfig = (event, rowData) => {
       
        // if(rowData.status.status_code > 103) {
         
        //     getOtherData('/retrieveConfigFile',{  instanceUrl: instanceUrl, accessToken: accessToken, apiVersion: 57, organizationId : userInfo.organizationId, folderName: rowData.name })
        //         .then(data =>{
        //             console.log(data);
        //             showConfig(data);

        //     });
        // }
        // else {
            getOtherData('/createConfig',{  instanceUrl: instanceUrl, accessToken: accessToken, apiVersion: 57, organizationId : userInfo.organizationId, folderName: rowData.name })
                .then(data =>{
                    showMessage("Template Retrieve Config file generated successfully!", "success");
                    setRetrieveList(data)
            });
        // }
        
    }

    const closeInputDialog = () => {
      setVariableInputDialog(false);
    };

  const showInputDialog= (data, name)=>{
      var dataMap = data.map((item)=>{
        return {'name': item, 'value': null}
      })
      setVariableDialogData({'folderName': name, 'defaultVariables': dataMap});
      setVariableInputDialog(true);
  }

  const saveVariable = (event)=>{
      console.log(event.updateDefaultValues);
      let response = fetch("https://salesforce-service.herokuapp.com/api" + `/saveVariableDefaultVale?instanceUrl=${instanceUrl}&accessToken=${accessToken}&organizationId=${userInfo.organizationId}&folderName=${event.folderName}` , {
        method: 'POST', 
        headers: {
          'Authorization': 'Bearer '+accessToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(event.updateDefaultValues)
      });
      closeInputDialog();
      response.then((e)=> {
        console.log(e);
        showMessage("Variable's default value saved successfully!", "success");
      }).catch((err)=>{
        console.log(err);
        showMessage("Not able to save variable's default value!", "error");
      });
        
  }

    const onErrorButton = (rowData) => {

      if(rowData.noDefaultRequiredVariables && rowData.noDefaultRequiredVariables.length > 0 && rowData.status.status_code === 102){
          showInputDialog(rowData.noDefaultRequiredVariables, rowData.name)
      }
      else {
        getOtherData('/retrieveConfigFile',{  instanceUrl: instanceUrl, accessToken: accessToken, apiVersion: 57, organizationId : userInfo.organizationId, folderName: rowData.name })
                .then(data =>{
                    console.log(data);
                    showConfig(data['errors']);
            });
      }
      
    }

    const editConfig = (event, rowData)=>{
      navigate('/editconfig/'+rowData.name);
    }

    const createFinalTemplateZipRequest = (rowData) => {
       
      if(rowData.status.status_code >= 106) {
          getOtherData('/createFinalZipRequest',{  instanceUrl: instanceUrl, accessToken: accessToken, apiVersion: 57, organizationId : userInfo.organizationId, folderName: rowData.name })
              .then(data =>{
                  console.log(data);
                  showMessage("Template Final zip is created successfully!", "success");
              });
      }
      
  }

  function previewFinalZipFiles(row) {
    navigate('/preview/' + row.name);
  }

  async function downloadFinalZip(row) {

    if(accessToken && instanceUrl){

        const response = await fetch(TEMPLATE_SERVER_URL +  `/downloadFinalTemplate?instanceUrl=${instanceUrl}&accessToken=${accessToken}&folderName=${row.name}&apiVersion=${row.version}&organizationId=${userInfo.organizationId}&isAutoInstall=${row.isAutoInstall}`, {
          mode: 'cors',
          method: 'GET', 
          headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
        })
        response.blob().then((res, filename)=>{
            
            const url = window.URL.createObjectURL(res);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = row.title ?row.title : "filename" ;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
            
        })
        .catch((error)=>{
                console.log("error in downloading:", error)
                
        });
      }
      else {
        navigate('/');
      }
        
  }

  async function deleteTemplateRequest(row){
    if(accessToken && instanceUrl){

      const response = await fetch(TEMPLATE_SERVER_URL +  `/cancel/${row.name}?instanceUrl=${instanceUrl}&accessToken=${accessToken}&apiVersion=${row.version}&organizationId=${userInfo.organizationId}`, {
        mode: 'cors',
        method: 'GET', 
        headers: {
          'Content-type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
      });
      if(response.status === 200) {
        showMessage("Template Retrieve Request deleted successfully!", "success");
      }
      else{
        showMessage("Error in deleting Template Retrieve Request!", "error");
      };
    }
    else {
      navigate('/');
    }
  }

  function beforeRequest(){
    setFlagLoader(1);
  }

  function postRespone(){
    setFlagLoader(0);
  }

  function showMessage(msg, variant, progressBarFlag=0){
    setFlagSnackbar(true);
    setSnackMessage(msg);
    setSnackVariant(variant);
    setFlagLoader(0);
  }

  function handleCloseSnackBar(event){
    setFlagSnackbar(false);
  }

  const options = [{ "label" :"Create Config", index: 0},
                   { "label" :"View Config", index: 1}, 
                   { "label" :"Edit Config", index: 2}, 
                   { "label" :"Create Zip", index: 3},
                   { "label" :"Download Zip", index: 4}];
  const [anchor, setAnchor] = useState(null);
  const [selectedRetrieveOptionMenu, setSelectedRetrieveOptionMenu] = useState(-1);

  const openRetrieveOptionMenu = (event) => {
    setAnchor(event.currentTarget);
  };

  const closeRetrieveOptionMenu = () => {
    setAnchor(null);
  };

  const onRetrieveOptionMenuItemClick = (event, index) => {
    setAnchor(null);
    setSelectedRetrieveOptionMenu(index);
  };

  const getOptionFilter = (rowData, index) => {
      const status = rowData.status.status_code;
      if(status < 106 && index===0){
        return true;
      }
      else if(status > 105 && index===1){
        return true;
      }
      else if(status > 105 &&  status < 110 && index===2){
        return true;
      }
      else if(status === 110 && index===3){
        return true;
      }
      else{
        return false;
      }
  }

  function openMenu(event, row) {
    let anchorElement = event.currentTarget;
    this.setState({currentRow: row}, () => {
      this.setState({menuAnchor: anchorElement});
      this.setState({menuOpen: true});
    });

  }
  const handleDeployTemplate = async (row, user, pwd, cD) => {
    console.log(cD);
    if (accessToken && instanceUrl) {
      try {
        const response = await fetch(TEMPLATE_SERVER_URL + `/deployTemplate?instanceUrl=${instanceUrl}&accessToken=${accessToken}&folderName=${row.name}&apiVersion=${row.version}&organizationId=${userInfo.organizationId}&isAutoInstall=${row.isAutoInstall}&Username=${user}&Password=${pwd}&customDomainFlag=${userInfo.customDomainFlag}&customDomain=${cD}`, {
          mode: 'cors',
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
        });
  
        if (response.status === 200) {
          showMessage("Deployed Template Successfully", "success");
        } else {
          const errorData = await response.json();
          console.log(errorData)
          showMessage(errorData.stdout || "Error in Deploying Template!", "error");
        }
      } catch (error) {
        console.error("Error deploying template:", error);
        showMessage("Error in Deploying Template!", "error");
      }
    } else {
      navigate('/');
    }
  }
  

    return (
        <div>
            <AppHeader />
            <Box sx={{ flexGrow: 1 }}>
              <ToastAlert  alertType="info" open={flagSnackbar} snackMessage={snackMessage} snackVariant={snackVariant} closeSnackBar={handleCloseSnackBar} message="info" >This is info.</ToastAlert>
            </Box>
            <Box className="progress-bar" sx={{ "display": flagLoader ? 'flex': 'none'}}>
              <CircularProgress  />
            </Box>
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
              <DialogTitle>Deploy Template</DialogTitle>
              <DialogContent>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <FormControlLabel
                          control={<Checkbox checked={deployToSourceOrg} onChange={handleCheckboxChange} name="deployToSourceOrg" />}
                          label="Deploy to Source Org"
                      />
                      <FormControlLabel
                          control={<Checkbox checked={deployToOtherOrg} onChange={handleCheckboxChange} name="deployToOtherOrg" />}
                          label="Deploy to Other Org"
                      />
                  </div>
                  {expandCredentials && (
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        const formData = new FormData(e.target);
                        const username = formData.get('username');
                        const password = formData.get('password');
                        const customDomain = customDomainFlag ? formData.get('customDomain') : 'https://login.salesforce.com';
                        handleSubmit(e, username, password,customDomain);
                      }}
                      style={{ marginTop: '1rem' }}
                    >
                      {/* Form header */}
                      <Typography variant="h6" gutterBottom>
                      </Typography>

                      {/* Username input */}
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        onChange={(e) => {handleUsernameChange(e)}}
                        value={username}
                      />

                      {/* Password input */}
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={(e) => {handlePasswordChange(e)}}
                        value={password}
                      />

                      {/* Custom domain checkbox */}
                      <FormControlLabel
                        control={
                          <Checkbox
                            margin="normal"
                            name="customDomainCheckbox"
                            id="customDomainCheckbox"
                            onChange={(e) => {handleDomainFlagChange(e)}}
                          />
                        }
                        label="Use Custom Domain"
                      />

                      {/* Custom domain input */}
                      {customDomainFlag && (
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          name="customDomain"
                          label="Custom Domain"
                          type="text"
                          id="customDomainId"
                          onChange={(e) => {handleCustomDomainChange(e)}}
                          value={customDomain}
                        />
                      )}

                      {/* Submit button inside the form */}
                      {(deployToSourceOrg || deployToOtherOrg) && (
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 2 }}
                        >
                          Deploy Template
                        </Button>
                      )}
                    </form>
                      )}

                      {/* Another button visible only when the first checkbox is selected */}
                      {deployToSourceOrg && (
                        <Button
                        onClick={() => {
                          // Call the handle deploy function here
                          
                          handleDeployTemplate(selectedRowData);
                          setUsername('');
                          setPassword('');
                          setDialogOpen(false)
                          setDeployToSourceOrg(false); 
                          setDeployToOtherOrg(false);
                          setCustomDomain('');
                          setExpandCredentials(false);
                        }}
                          fullWidth
                          variant="contained"
                          sx={{ mt: 2, width: '50%', marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                        >
                          Deploy Template
                        </Button>
                      )}

              </DialogContent>
              <DialogActions>
                  <Button
                      onClick={() => {
                        setUsername('');
                        setPassword('');
                        setDialogOpen(false)
                        setDeployToSourceOrg(false); 
                        setDeployToOtherOrg(false);
                        setCustomDomain('');
                        setExpandCredentials(false);
                      }}

                    >
                      Cancel
                    </Button>
              </DialogActions>
          </Dialog>





            <div style={{maxWidth: '100%', marginTop:"5%"}}>
                {open ?(<ConfigJSONViewer open= {open} onClose={closeDialog} data={configData}></ConfigJSONViewer>):null}
                {openVariableDialog ?(<VariableInputDialog open= {openVariableDialog} onClose={closeInputDialog} createSaveVariableRequest={saveVariable} data={variableDialogData}></VariableInputDialog>):null}
                <MaterialTable
                    columns={[
                        { title: 'App Name', field: 'name' },
                        { title: 'Created Date', field: 'createdOn' },
                        { title: 'AutoInstall', field: 'isAutoInstall' },
                        { title: 'Progress', field: 'status.status_text' },
                        { title: 'Errors', field: 'errorCount', align: 'center', render: rowData =>{
                          return( 
                            <div>
                              {((!rowData.errorCount) || rowData.errorCount===0)? 
                               <div style={{ color:  "#008240", textAlign: 'center'}}>{rowData.errorCount}</div>:
                               <div style={{textAlign:'center'}}> 
                                <Button onClick={()=>onErrorButton(rowData)} style={{     
                                    borderRadius: "50%", background: 'red',
                                    minWidth: '30px',
                                    lineHeight: "1.3",
                                    color: 'white'}}>
                                  {rowData.errorCount}</Button>
                              </div>
                              }
                              {/* <ActionMenu /> */}
                            </div>
                        )} }
                    ]}
                    data={retrieveList}
                    detailPanel = { createDetailPanel}
                    title={"Retrieve Template List"}
                    options={{
                        paging: false,
                        selection: false,
                        search: false,
                        actionsColumnIndex: -1
                    }}
                    actions={[
                      rowData => ({
                        icon: 'settings',
                        tooltip: 'Create Config',
                        onClick: createConfig,
                        disabled: rowData.status.status_code < 103 || rowData.status.status_code > 105
                      }),
                      rowData => ({
                        icon: 'edit',
                        tooltip: 'Edit Config',
                        onClick: editConfig,
                        disabled: rowData.status.status_code < 105
                      }),
                      rowData => ({
                        icon: 'publish',
                        tooltip: 'Create Final Template',
                        onClick: (event, rowData) => createFinalTemplateZipRequest(rowData),
                        disabled: (rowData.status.status_code < 106 || rowData.status.status_code > 109 || rowData.errorCount>0)
                      }), 
                      rowData => ({
                        icon: 'preview',
                        tooltip: 'Preview Final Template',
                        onClick: (event, rowData) => previewFinalZipFiles(rowData),
                        disabled: rowData.status.status_code !== 110
                      }),
                      rowData => ({
                        icon: 'download',
                        tooltip: 'Download Final Template',
                        onClick: (event, rowData) => downloadFinalZip(rowData),
                        disabled: rowData.status.status_code !== 110
                      }),
                      rowData => ({
                        icon: 'cloud_upload',
                        tooltip: 'Deploy Template ',
                        onClick: (event, rowData) => {
                          setSelectedRowData(rowData);
                          setDialogOpen(true);
                        },
                        disabled: rowData.status.status_code !== 110
                      }),
                      
                      rowData => ({
                        icon: 'delete',
                        tooltip: 'Delete Template Request',
                        onClick: (event, rowData) => deleteTemplateRequest(rowData)
                      })
                  ]}
                    
                />
            </div>
        </div>
    );


}


export default Retriever;