import React, { useState, useEffect } from 'react';
import axios, { all } from 'axios';
import MaterialTable from 'material-table'
import { Button } from '@material-ui/core';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {   Checkbox, TextField, FormControlLabel } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import LinkOffIcon from '@mui/icons-material/LinkOff';

import Cookies from 'js-cookie';
import 'react-notifications/lib/notifications.css';
import Collapsible from 'react-collapsible';


import AppHeader from "./AppHeader";

import { TEMPLATE_SERVER_URL } from './common/constants.js'

import { useNavigate } from "react-router-dom";
import RetrieveRequestModal from './common/dialogs/retrieveRequestModal';
import ToastAlert from './common/toast/toastWithAlert';
import AppsForATemplateDialog from './common/dialogs/listAppsForTemplateDialog';
import SelectAllApps from './common/dialogs/selectAllAppsforTemplate';
// import ActionMenu from './components/actionMenu';


function Table(props) {
  
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dataCloudTemplateChecked, setDataCloudTemplateChecked] = useState(false);
  const [crmaTemplateChecked, setCrmaTemplateChecked] = useState(false);
  const [dataTransformId, setDataTransformId] = useState('');
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const handleButtonClick = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleDataCloudTemplateChange = (event) => {
    setDataCloudTemplateChecked(event.target.checked);
    if (event.target.checked) {
      setCrmaTemplateChecked(false); // Uncheck the other checkbox
    }
  };

  const handleCrmaTemplateChange = (event) => {
    setCrmaTemplateChecked(event.target.checked);
    if (event.target.checked) {
       setDataTransformId('');
      setDataCloudTemplateChecked(false); // Uncheck the other checkbox
    }

  };

  const handleDataTransformIdChange = (event) => {
    setDataTransformId(event.target.value);
  };


  const [tableData, setTableData] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [flag, setFlag] = useState(0)
  const [clsFolders, setClsFolders] = useState("blue");
  const [clsTemplates, setClsTemplates] = useState("black");
  const [modalOpen, setModalOpen] = useState(0);
  const [flagLoader, setFlagLoader]= useState(1);

  const [flagSnackbar, setFlagSnackbar]= useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");
  const [snackVariant, setSnackVariant] = React.useState("");
  

  const [templates, setTemplates]=useState([])
  const [folders, setFolders] = useState([]);
  const accessToken=Cookies.get('accessToken')
  const instanceUrl= Cookies.get('instanceUrl')
  const userInfo = {
        username: Cookies.get('username'), 
        fullName: Cookies.get('userFullName'), 
        organizationId: Cookies.get('organizationId')
  };
  const [havingDependencies,setHavingDependencies]=useState([])
  const [normal,setNormal]=useState([])
  

  const templateIdToName = new Map();

  const [open, setOpen] = React.useState(false);
  const [openRetrieve, setOpenDialog] = React.useState(false);
  const anchorRef = React.useRef(null);

  let navigate = useNavigate();
  
  function handleCloseSnackBar(event){
    setFlagSnackbar(false);
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleCloseToggle = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleLogout = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    removeCookies();
    navigate('/');
    setOpen(false);
  };

  const removeCookies = () => {
    Cookies.remove('accessToken');
    Cookies.remove('instanceUrl');
    Cookies.remove('username');
    Cookies.remove('userFullName');

  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  useEffect(() => {
   // setFlagLoader(1)
    if(instanceUrl && accessToken && TEMPLATE_SERVER_URL){
      setFlagLoader(1)
        getData('/templates',{ answer: 42, instanceUrl: instanceUrl, accessToken: accessToken })
            .then(data =>{
                setTemplates(data)
        });


        getData('/folders',{ answer: 42, instanceUrl: instanceUrl, accessToken: accessToken })
            .then(data =>{
                setFolders(data)
               setFlagLoader(0)
        }).catch(e=> {
            setFlagLoader(0);
            showMessage("Template Retrieve Request Caught Error!", "error");
        });

        
    }
    else {
      navigate('/');
    }
    
}, [instanceUrl,accessToken, TEMPLATE_SERVER_URL])


async function getData(url='', data = {}) {
  // Default options are marked with *
  const response = await fetch(TEMPLATE_SERVER_URL + url + `?instanceUrl=${data.instanceUrl}&accessToken=${data.accessToken}`, {
    mode: 'cors',
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Content-type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
    // body:  JSON.stringify(get_templates_body)
    
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

  async function handleClickOpen () {
    setFlagLoader(1)
    console.log("insidedelete ")
    let temp = []
    let temp1 = []
    for(let i=0;i<selectedRows.length;i++){

        const data=await getData('/folder/dependencies/'+selectedRows[i].id,{ answer: 42, instanceUrl: instanceUrl, accessToken: accessToken })
        // console.log(data.dashboards," dashh")
        if(!(data.dashboards==null))
        {
            // console.log(data.dashboards.dependencies,"---")
            temp.push(data);
        }
        else{
            temp1.push(data.name)
        }
     
    }
    setFlagLoader(0)
    setHavingDependencies(temp)
    setNormal(temp1)
    
    // console.log( havingDependencies,"namera")
    setModalOpen(1);
  };
  // console.log("outsidedelete ")
  const handleClose = () => {
    setModalOpen(0);
  };

  async function deleteSelectedRow(index){
  
    if(index<selectedRows.length){
      setFlagLoader(1)
      const response = await fetch(TEMPLATE_SERVER_URL + `/folder/${selectedRows[index].id}?instanceUrl=${instanceUrl}&accessToken=${accessToken}` , {
        method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
        headers: {
          'Authorization': 'Bearer '+accessToken,
          'Content-Type': 'application/json'
        }
        
      });
      // console.log(response,"response");
      if(response.status < 400){
        setFlagLoader(1)
        deleteSelectedRow(index+1);
      }
      else{
        setFlagLoader(0)
        let tempTableData = [];
        const selectedRowsSet = new Set();
        for(let i=0;i<index;i++){
          selectedRowsSet.add(selectedRows[i].id);
        }
        tableData.map((item) =>{
          if(!selectedRowsSet.has(item.id)){
            tempTableData.push(item);
          }
        })
        NotificationManager.info('Some files could not be deleted,Please Try again');
        setTableData(tempTableData);
        setSelectedRows([]);
      }
      
    }
    else{
      setFlagLoader(0);
      let tempTableData = [];
        const selectedRowsSet = new Set();
        for(let i=0;i<index;i++){
          selectedRowsSet.add(selectedRows[i].id);
        }
        tableData.map((item)=>{
          if(!selectedRowsSet.has(item.id)){
            tempTableData.push(item);
          }
        })
        NotificationManager.success('Selected files deleted successfully');
        setTableData(tempTableData);
        setSelectedRows([]);
    }
  }
  const deleteSelectedFiles  = async () => {
      
      deleteSelectedRow(0);
      handleClose()
  }

  useEffect(() => {
   
    const tempArr = []
    if(templates.templates){
      templates.templates.map((item)=>{
        templateIdToName.set(item.id,item.label)
        console.log(templateIdToName,"mappp")
      })
    }
    if (flag && templates.templates) {
      templates.templates.map((item) => {
        const obj = {
          id: item.id,
          name: item.name,
          title: item.label,
          createdBy: item.createdBy.name,
          createdOn: item.createdDate,
          lastModifiedBy: item.lastModifiedBy.name,
          lastModifiedDate: item.lastModifiedDate
        }
        if(item.id.substring(0,4)!="sfdc")
          tempArr.push(obj)
      })
    }
    else if(!flag && folders.folders){
      // console.log(folders.folders)
      folders.folders.map((item) => {
        const templateId=item.templateSourceId
        // console.log(templateId,"---")
        let str = templateId==null ? "N/A" : (templateId.substring(0,4)=="sfdc"?"File based":"BPO based");
        // str = ((!(templateId==null)&&templateId.substring(0,4)=="sfdc")?"File based":"BPO based" )
        const src=((!(templateId==null))?templateIdToName.get(templateId):'N/A')
        const obj = {
          id: item.id,
          title: item.label,
          basis: str,
          source: src,
          createdBy: item.createdBy.name,
          createdOn: item.createdDate,
          lastModifiedBy: item.lastModifiedBy.name,
          lastModifiedDate: item.lastModifiedDate,
          templateSourceId: item.templateSourceId
        }
        tempArr.push(obj)
      })
    }

    setTableData(tempArr)
  }, [folders,templates, flag])

  const handleFlagChangeTemplates = () => {
    setFlag(1);
    setClsTemplates((clsTemplates) => (clsTemplates = "blue"))
    setClsFolders((clsFolders) => (clsFolders = "black"))

  }

  const handleFlagChangeFolders = () => {
    setFlag(0);
    setClsTemplates((clsTemplates) => (clsTemplates = "black"))
    setClsFolders((clsFolders) => (clsFolders = "blue"))
  }
var parser = new DOMParser();

  
var raw=""
const [rawStatus,setRawStatus]=useState("")

const [signOpen, setSignOpen] = useState(false);
const [row, setRow] = useState({});
const handleSignOpen = (row) => {
  setSignOpen(true);
  setRow(row);
};

const cancelRetrieveRequest = (event, arg) => {
    setSignOpen(false);
}
const createRetrieveRequest = (event, arg) => {
  setSignOpen(false);
  callCreateRetrieveRequestAPI(event.row, event.isAutoInstall, event.fetchParentOption, event.version);
}


   function handleClickDownloadTemplate (row) {
      handleSignOpen(row);    
  }

  async function callCreateRetrieveRequestAPI(row, isAutoInstall, fetchParentOption, version){
    setFlagLoader(1);
    if(accessToken && instanceUrl){

      fetch(TEMPLATE_SERVER_URL +  `/retrieve?instanceUrl=${instanceUrl}&accessToken=${accessToken}&folderName=${row.name}&apiVersion=${version}&organizationId=${userInfo.organizationId}&isAutoInstall=${isAutoInstall}&fetchParentOption=${fetchParentOption}`, {
        mode: 'cors',
        method: 'GET', 
        headers: {
          'Content-type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
      }).then((resp)=>{
        if(resp.status === 400){
          showMessage("Template Retrieve Request Already in progress. Check Retriever page!", "error");
        }
        else if(resp.status === 200){
          showMessage("Template Retrieve Request created!", "success");
        } 
        else {
          showMessage("Some error occured.", "error");
        }
      })
      .catch((error)=>{
          showMessage("Template Retrieve Request Caught Error!", "error");
      })
      
    }
    else {
        handleLogout();
    }
  }

  function showMessage(msg, variant, progressBarFlag=0){
    setFlagSnackbar(true);
    setSnackMessage(msg);
    setSnackVariant(variant);
    setFlagLoader(progressBarFlag);
  }

  const [openAppsForATemplateDialog, setOpenAppsForATemplateDialog] = React.useState(false);
  const[configAppsForATemplateDialog, setConfigAppsForATemplateDialog] = React.useState({});
  const [selectallTemplateDialog, setselectallTemplateDialog] = React.useState(false);
  const[configselectallForATemplateDialog, setconfigselectallforATemplateDialog] = React.useState({});

  const closeAllAppsForATemplateDialog = () => {
    setselectallTemplateDialog(false);
  };


  const closeAppsForATemplateDialog = () => {
    setOpenAppsForATemplateDialog(false);
  };

  async function listFolderForThisTemplate(templateSourceId) {
    setFlagLoader(1);
    setConfigAppsForATemplateDialog({}); 
    let response = await fetch(TEMPLATE_SERVER_URL + `/folders?instanceUrl=${instanceUrl}&accessToken=${accessToken}&templateSourceId=${templateSourceId}` , {
      method: 'GET', 
      headers: {
        'Authorization': 'Bearer '+accessToken,
        'Content-Type': 'application/json'
      }
      
      });
      let dataPromise = response.json();
      dataPromise.then((resp)=>{
          setFlagLoader(0);
          console.log(resp.folders);
          if(resp.folders.length===0){
            showMessage("No Apps(Folders) founded to decouple!", "error");
          }
          else {
            setConfigAppsForATemplateDialog({
              templateSourceId,
              folders: resp.folders,
              count: resp.totalSize
            }); 
            setOpenAppsForATemplateDialog(true);
          }
      }).catch((error)=>{
          setFlagLoader(0);
          showMessage("Listing related folder caught error" + error, "error");
      })
  }
  async function FolderForThisTemplate(templateSourceId) {
    setFlagLoader(1);
    setconfigselectallforATemplateDialog({}); 
    let response = await fetch(TEMPLATE_SERVER_URL + `/folders?instanceUrl=${instanceUrl}&accessToken=${accessToken}&templateSourceId=${templateSourceId}` , {
      method: 'GET', 
      headers: {
        'Authorization': 'Bearer '+accessToken,
        'Content-Type': 'application/json'
      }
      
      });
      let dataPromise = response.json();
      dataPromise.then((resp)=>{
          setFlagLoader(0);
          console.log(resp.folders);
          if(resp.folders.length===0){
            showMessage("No Apps(Folders) founded to decouple!", "error");
          }
          else {
            setconfigselectallforATemplateDialog({
              templateSourceId,
              folders: resp.folders,
              count: resp.totalSize
            }); 
            setselectallTemplateDialog(true);
          }
      }).catch((error)=>{
          setFlagLoader(0);
          showMessage("Listing related folder caught error" + error, "error");
      })
  }
function handleDeployClick (rowdata) {
  let response = null;
  setFlagLoader(1);
  if(rowdata.templateSourceId){
    response = fetch(TEMPLATE_SERVER_URL + `/dctemplate/${rowdata.templateSourceId}?instanceUrl=${instanceUrl}&accessToken=${accessToken}&folderId=${rowdata.id}&dataTransformId=${dataTransformId}` , {
      method: 'PUT', 
      headers: {
        'Authorization': 'Bearer '+accessToken,
        'Content-Type': 'application/json'
      }
      
    });
    console.log(response.data)
  }
    else {
    console.log('Row Data:', rowdata.id);
    try {
      response =  axios.get(`https://salesforce-service.herokuapp.com/dcTemplate?dataTransformId=${dataTransformId}&rowdata=${rowdata.id}&instanceUrl=${instanceUrl}&accessToken=${accessToken}`,);
      // Check if the response contains a success message
        showMessage('Created template successfully');
     
    } catch (error) {
      console.error(error);
      showMessage('Error creating template: ');
    }
  };
  let msg = rowdata.templateSourceId ? 'Updated Template' : 'Created Template';
  response.then((resp)=>{
    setFlagLoader(0);
    if(resp.status === 200){
      showMessage(msg + " successfully!", "success");
    } 
    else {
      resp.json().then(((e)=>{
          showMessage(msg + " caught error!\n" + e.message, "error")
          }
        ));
    }
  }).catch((error)=>{
    setFlagLoader(0);
      showMessage(msg + " caught error" + error, "error");
  })
  return null;
}

function handleClickCreateUpdateTemplate(rowData) {
  let response = null;
  setFlagLoader(1);
  if(rowData.templateSourceId){
    response = fetch(TEMPLATE_SERVER_URL + `/templates/${rowData.templateSourceId}?instanceUrl=${instanceUrl}&accessToken=${accessToken}&folderId=${rowData.id}` , {
      method: 'PUT', 
      headers: {
        'Authorization': 'Bearer '+accessToken,
        'Content-Type': 'application/json'
      }
      
    });
    console.log(response.data)
  }
  else {
    response = fetch(TEMPLATE_SERVER_URL + `/templates?instanceUrl=${instanceUrl}&accessToken=${accessToken}&folderId=${rowData.id}` , {
      method: 'POST', 
      headers: {
        'Authorization': 'Bearer '+accessToken,
        'Content-Type': 'application/json'
      }
      
    });
    
  }
  let msg = rowData.templateSourceId ? 'Update Template' : 'Create Template';
  response.then((resp)=>{
    setFlagLoader(0);
    if(resp.status === 200){
      showMessage(msg + " successfully!", "success");
    } 
    else {
      resp.json().then(((e)=>{
          showMessage(msg + " caught error!\n" + e.message, "error")
          }
        ));
    }
  }).catch((error)=>{
    setFlagLoader(0);
      showMessage(msg + " caught error" + error, "error");
  })
  return null;
}

  function handleClickDecoupleTemplate(row) {
    listFolderForThisTemplate(row.id);
 }
 function handleClickDecoupleAndDeleteTemplate(row) {

  FolderForThisTemplate(row.id);
}
  async function handleDecoupleandDeleteApp(selectedFoldersToDecouple) {
    console.log('decoupling app');
    setselectallTemplateDialog(false);
    setFlagLoader(1);
    try{ 
          const promiseObjects = [];
          for(let index=0; index<selectedFoldersToDecouple.length; index++){
            const rowData = selectedFoldersToDecouple[index];
            
            
              if(rowData.templateSourceId){
                promiseObjects.push(fetch(TEMPLATE_SERVER_URL + `/folders/${rowData.id}?instanceUrl=${instanceUrl}&accessToken=${accessToken}&templateSourceId=${rowData.templateSourceId}` , {
                  method: 'PUT', 
                  headers: {
                    'Authorization': 'Bearer '+accessToken,
                    'Content-Type': 'application/json'
                  }
                  
                }));
              }
          }

          const results = await Promise.all(promiseObjects);
          const dataPromises = results.map(result => result.json());
          const finalData = await Promise.all(dataPromises);
          setFlagLoader(0);
          showMessage("Decoupled folder and Template deleted successfully!", "success");
          return finalData;
          
        }catch (error) {
          setFlagLoader(0);
          console.log(error);
          showMessage("Decoupling folder caught error" + error, "error");
        }
      
    
    
  }
  async function handleDecoupleApp(selectedFoldersToDecouple) {
    console.log('decoupling app');
    setOpenAppsForATemplateDialog(false);
    setFlagLoader(1);
    try{ 
          const promiseObjects = [];
          for(let index=0; index<selectedFoldersToDecouple.length; index++){
            const rowData = selectedFoldersToDecouple[index];
            
            
              if(rowData.templateSourceId){
                promiseObjects.push(fetch(TEMPLATE_SERVER_URL + `/folder/${rowData.id}?instanceUrl=${instanceUrl}&accessToken=${accessToken}&templateSourceId=${rowData.templateSourceId}` , {
                  method: 'PUT', 
                  headers: {
                    'Authorization': 'Bearer '+accessToken,
                    'Content-Type': 'application/json'
                  }
                  
                }));
              }
          }

          const results = await Promise.all(promiseObjects);
          const dataPromises = results.map(result => result.json());
          const finalData = await Promise.all(dataPromises);
          setFlagLoader(0);
          showMessage("Decoupled folder successfully", "success");
          return finalData;
          
        }catch (error) {
          setFlagLoader(0);
          console.log(error);
          showMessage("Decoupling folder caught error" + error, "error");
        }
      
    
    
  }


  const renderButton = (rowData) => {
    if (!rowData.templateSourceId) {
      return (
        <button style={{ width: "120px" }} onClick={handleButtonClick}>
          <span>Create Template</span>
        </button>
      );
    } else if (rowData.templateSourceId.substring(0, 4) === "sfdc") {
      return <></>;
    } else {
      return (
        <button style={{ width: "120px" }} onClick={handleButtonClick}>
          <span>Update Template</span>
        </button>
      );
    }
  };

  const handleRowClick = (event, rowData) => {
    setSelectedRowData(rowData);
  };

  return (
    <>
       <style>{`
        .blue {color: #3789d1}
        .white {color: white}
      `}</style>
      <Box sx={{ flexGrow: 1 }}>
        <AppHeader />
      </Box>
      <Box sx={{ flexGrow: 1, marginLeft: '10px' }}>
          <ToastAlert  alertType="info" open={flagSnackbar} snackMessage={snackMessage} snackVariant={snackVariant} closeSnackBar={handleCloseSnackBar} message="info" >This is info.</ToastAlert>
      </Box>
      <Box className="progress-bar" sx={{ "display": flagLoader ? 'flex': 'none'}}>
          <CircularProgress  />
      </Box>
      <div style={{ maxWidth: '100%' }}>
        <div style={{alignItems: 'left',padding: '3%'}}>
          <Button className={clsFolders} onClick={handleFlagChangeFolders} variant="outlined">Folders</Button>
          <Button className={clsTemplates} onClick={handleFlagChangeTemplates} variant="outlined">Templates</Button> 
        </div> 

        <NotificationContainer/>
        
        {tableData.length ?
          <div>
          
          <Dialog 
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete?"}
        </DialogTitle>
        { havingDependencies.length ?(
            <DialogContent>
              <DialogContentText id="alert-dialog-description" style={{color:'purple'}}>
              The following apps have assets that are used in other places.
              </DialogContentText>
              <List sx={{ pt: 0 }} style={{overflow: 'scroll', color:'red', cursor:'pointer'}} >
              {
                havingDependencies.map((item)=> (
                  
                  <Collapsible  trigger={item.name}>
                  <div style={{color:'black',cursor:'text'  }}>  
                  {
                      item.dashboards.dependencies.map((objj)=>(
                          <p>
                            {objj.name} {"("}
                            {objj.folder.label} {")"} 
                          </p> 
                      ))
                  }
                  </div>
                </Collapsible>
                ))
              }  
              </List>
            </DialogContent>):null
          }
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{color:'purple'}}>
            No other app is dependent on these.
          </DialogContentText>
          <List sx={{ pt: 0 }} style={{overflow: 'scroll'}}>
          {
            normal.map((item)=> (
              <ListItemText primary={item} />
            ))
          }  
          </List>
        </DialogContent>

      </Dialog>
      <RetrieveRequestModal row={row} signOpen={signOpen} handleClose={cancelRetrieveRequest } createRequest={createRetrieveRequest}/>
      
      {selectallTemplateDialog ? <SelectAllApps data={configselectallForATemplateDialog} open={selectallTemplateDialog} onClose={closeAllAppsForATemplateDialog} decoupleApps={handleDecoupleandDeleteApp}></SelectAllApps>: null}
      {openAppsForATemplateDialog ? <AppsForATemplateDialog data={configAppsForATemplateDialog} open={openAppsForATemplateDialog} onClose={closeAppsForATemplateDialog} decoupleApps={handleDecoupleApp}></AppsForATemplateDialog>: null}
         
          <MaterialTable
                onRowClick={handleRowClick}

            columns={flag?[
              { title: 'Title', field: 'title' },
              { title: 'Created By', field: 'createdBy' },
              { title: 'Created On', field: 'createdOn' },
              { title: 'Last Modified By', field: 'lastModifiedBy' },
              { title: 'Last Modified On', field: 'lastModifiedDate' },
      

            ]:[
              { title: 'Title', field: 'title' },
              { title: 'Type', field: 'basis' },
              { title: 'Source Template', field: 'source' },
              { title: 'Created By', field: 'createdBy' },
              { title: 'Created On', field: 'createdOn' },
              { title: 'Last Modified By', field: 'lastModifiedBy' },
              { title: 'Last Modified On', field: 'lastModifiedDate' },
              { title: 'Action', field: '', render: renderButton}
            ]}
            data={tableData}
            title={flag ? "Templates" : "Folders"}
            options={{
              paging: false,
              selection: flag ? false: true,
              actionsColumnIndex: -1
            }}
            onSelectionChange={(rows) => setSelectedRows(rows)}
            // component overriding
            // components={{
            //   Action: (props) => <ActionMenu />
            // }}
            actions={!flag ? [
              {
                icon: 'delete',
                tooltip: 'Delete all selected rows',
                onClick: handleClickOpen
              }]:
              [{
                icon: 'download',
                tooltip: 'Template Retrieve Request',
                onClick: (event, rowData) => {handleClickDownloadTemplate(rowData)}
              },
              {
                icon: LinkOffIcon,
                tooltip: 'Decouple',
                onClick: (event, rowData) => {handleClickDecoupleTemplate(rowData)}
              },
              {
                icon: DeleteIcon,
                tooltip: 'Decouple and Delete Template',
                onClick: (event, rowData) => {handleClickDecoupleAndDeleteTemplate(rowData)}
              }
            ]
            }
            
          />
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Choose Template</DialogTitle>
          <DialogContent>
            <FormControlLabel
              control={<Checkbox checked={dataCloudTemplateChecked} onChange={handleDataCloudTemplateChange} />}
              label="Data Cloud Template"
            />
            {dataCloudTemplateChecked && (
              <TextField
                label="Data Transform ID"
                value={dataTransformId}
                onChange={handleDataTransformIdChange}
                variant="outlined"
                fullWidth
                
              />
            )}
            <FormControlLabel
              control={<Checkbox checked={crmaTemplateChecked} onChange={handleCrmaTemplateChange} />}
              label="CRMA Template"
            />
          </DialogContent>
          <DialogActions>
            {dataCloudTemplateChecked && selectedRowData &&  (
              <Button   type="submit"
              fullWidth
              variant="contained"
              
              sx={{ mt: 2 }} onClick={() => { 
                if (!dataTransformId) {
                  alert('Please enter a Data Transform ID');
                  return;
                }
                
              handleDeployClick(selectedRowData);
              setDataCloudTemplateChecked(false); 
              setCrmaTemplateChecked(false);
              setDataTransformId('');
              setDialogOpen(false);
            }} 
              color="primary">
              Create/Update Data Cloud Template
              </Button>
            )}
            {crmaTemplateChecked && selectedRowData && (
              <Button  type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2 }} onClick={() => { 
                handleClickCreateUpdateTemplate(selectedRowData); 
                setDataCloudTemplateChecked(false); 
                setCrmaTemplateChecked(false);
                setDataTransformId('');
                setDialogOpen(false);
                 }} color="primary">
                Create/Update CRMA Template
              </Button>
            )}
         <Button onClick={() => {
            setDataCloudTemplateChecked(false); 
            setCrmaTemplateChecked(false);
            setDataTransformId('');
            setDialogOpen(false);
          }} color="primary">
              Cancel
          </Button>

          </DialogActions>
        </Dialog>

          </div>
           : null

        }

      </div>
    </>

  );
}

export default Table;
